import React from 'react'
import PropTypes from 'prop-types'
import { compose, setDisplayName, withState } from 'recompose'

import { Button } from '@jsluna/button'

import TextInput from './TextInput'
import InputGroup from './InputGroup'
import InputControl from './InputControl'
import InputAction from './InputAction'

const Password = ({ hasToggle, setTextVisibility, showText, ...props }) => (
  <InputGroup>
    <InputControl>
      <TextInput {...props} type={showText ? 'text' : 'password'} />
    </InputControl>
    {hasToggle && setTextVisibility && (
      <InputAction>
        <Button onClick={() => setTextVisibility(!showText)}>
          {showText ? 'Hide' : 'Show'}
          <span className="ln-u-visually-hidden">password</span>
        </Button>
      </InputAction>
    )}
  </InputGroup>
)

Password.propTypes = {
  showText: PropTypes.bool,
  hasToggle: PropTypes.bool,
  setTextVisibility: PropTypes.func,
}

Password.defaultProps = {
  showText: false,
  hasToggle: false,
  setTextVisibility: undefined,
}

Password.displayName = 'Password'

export { Password as PasswordComponent }
export default compose(
  setDisplayName('Password'),
  withState('showText', 'setTextVisibility', false),
)(Password)
